import { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import commonStyles from './commonStyles'
import { Modal } from './Modal'
import { baseUrl, getImageUrl, thumbnailUrl, useModal } from '../store'
import { Box, Button, Container, List, ListItemButton, ListItemText } from '@mui/material'

// const Item = (props) => {
// 	const modal = useModal()
// 	const [showImg, setShow] = useState(false)
// 	const [image, setImage] = useState(false)
// 	const handleClick = async () => {
// 		modal.setShow(true)
// 		modal.setData({ ...props })
// 	}
// 	useEffect(() => {
// 		setImage(getImageUrl(props.productCode))
// 	}, [])
// 	return (
// 		<>
// 			<div
// 				onMouseLeave={() => setShow(false)}
// 				onMouseEnter={() => setShow(true)}
// 				onClick={handleClick}
// 				className={css(itemStyles.item)}
// 			>
// 				<div className={css(itemStyles.blocks, commonStyles.paragraph)}>
// 					<p>{props.idx + 1}.</p>
// 				</div>
// 				<div className={css(itemStyles.blocks, commonStyles.paragraph)}>
// 					<p>{props.productCode}</p>
// 				</div>
// 				<div className={css(itemStyles.blocks, commonStyles.paragraph)}>
// 					{showImg && (
// 						<div className={css(styles.hover)}>
// 							<img
// 								height="400px"
// 								width="400px"
// 								src={thumbnailUrl + props.productCode}
// 								alt=""
// 							/>
// 						</div>
// 					)}
// 					<p style={{ width: '100%', marginRight: '5%' }}>
// 						{props.name}
// 					</p>
// 				</div>
// 				<div className={css(itemStyles.blocks, commonStyles.paragraph)}>
// 					<p>{props.updatedAt}</p>
// 				</div>
// 			</div>
// 		</>
// 	)
// }


// const ItemsContainer = ({ data, setData, setEditing }) => {
// 	return (
// 		<div className={css(styles.container)}>
// 			{data.map((x, i) => (
// 				<Item idx={i} key={i} {...x} />
// 				))}
// 		</div>
// 	)
// }

const Item = ({ idx, productData, selectedIndex, setSelectedIndex }) => {
	const [showImg, setShowImg] = useState(false)
	const modal = useModal()

	const handleListItemClick = (index) => {
		console.log('ListItemButton click with index', index, productData)
		setSelectedIndex(index);
		modal.setData(productData)
		// console.log('modal in ItemsContainer', modal)
	};

	return (
		<ListItemButton
			selected={selectedIndex === idx}
			onClick={() => handleListItemClick(idx)}
			sx={itemStyles.item}
			onMouseLeave={() => setShowImg(false)}
			onMouseEnter={() => setShowImg(true)}
		>
			<p>{idx + 1}.</p>
			<p>{productData.productCode}</p>
			{showImg && (
				<Container sx={itemStyles.thumbnail}>
					<img
						height="400px"
						width="400px"
						src={thumbnailUrl + productData.productCode}
						alt=""
					/>
				</Container>
			)}
			<p>{productData.updatedAt}</p>
		</ListItemButton>
	)
}
const ItemsContainer = ({ data, setData, setEditing }) => {
	const [selectedIndex, setSelectedIndex] = useState();

	return (
		<List component="div" aria-label="all products" sx={styles.container} >
			{data.length ?
				data.map((productData, i) => (
					<Item
						idx={i}
						key={i}
						productData={productData}
						selectedIndex={selectedIndex}
						setSelectedIndex={setSelectedIndex}
					/>
				)) : (<p>No Products Found</p>)}
		</List>
	)
}

export default ItemsContainer

const styles = {
	container: {
		width: '90%',
		margin: '0 auto',
		height: '60%',
		overflowY: 'auto',
	},
}

const itemStyles = {
	item: {
		gap: 3,
		'&.Mui-selected': {
			color: '#ffffff',
			backgroundColor: 'rgba(0,0,0,0.6)',
			'&:hover': {
				color: '#000000',
				backgroundColor: 'rgba(0,0,0,0.3)',
			},
			'&:focus': {
				color: '#000000',
				backgroundColor: 'rgba(0,0,0,0.3)',
			}
		}
	},
	thumbnail: {
		position: 'fixed',
		top: 200,
		left: 200,
		pointerEvents: 'none',
	},
	// show: {
	// 	marginLeft: 'auto',
	// }
}

// const styles = StyleSheet.create({
// 	container: {
// 		width: '90%',
// 		height: '80%',
// 		margin: 'auto',
// 		overflowY: 'auto',
// 		overflowX: 'visible',
// 	},
// 	hover: {
// 		position: 'absolute',
// 		top: 0,
// 		pointerEvents: 'none',
// 	},
// })

// const itemStyles = StyleSheet.create({
// 	item: {
// 		display: 'flex',
// 		justifyContent: 'flex-start',
// 		width: '80%',
// 		boxShadow:
// 			'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
// 		cursor: 'pointer',
// 		':hover': {
// 			boxShadow:
// 				'0 4px 8px 0 rgba(110, 110, 110, 0.2), 0 6px 20px 0 rgba(110, 110, 110, 0.19)',
// 		},
// 		paddingLeft: '2%',
// 		paddingRight: '2%',
// 		overflowX: 'visible',
// 	},
// 	blocks: {
// 		margin: '0% 3.5% 0 0',
// 	},
// })
