import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { css, StyleSheet } from 'aphrodite'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { baseUrl } from '../store'
import { DataTexture } from 'three'

const INITIAL_FORM_STATE = {
	identifier: '',
	password: '',
}

const AuthUserUI = ({ setValidated }) => {
	const [formData, setFormData] = useState(INITIAL_FORM_STATE)
	const [userResponseData, setUserResponseData] = useState()
	const [isLoggedIn, setIsLoggedIn] = useState()
	const [errorMsg, setErrorMsg] = useState()
	const [rememberMe, setRememberMe] = useState(false)

	function focusInput() {
		const elem = document.getElementById('text')
		const inputDiv = document.getElementById('inputDiv')

		inputDiv.style.marginTop = '10%'
		document.body.scrollTop = elem.offsetTop
	}

	useEffect(() => {
		if (isLoggedIn) {
			console.log(isLoggedIn)
			localStorage.setItem('token', isLoggedIn)
			localStorage.setItem('timestamp', new Date().getTime())
			axios.defaults.headers.common.authorization = `Bearer ${localStorage.token}`
			setValidated(true)
		}
	}, [isLoggedIn])
	useEffect(() => {
		if (
			localStorage.token &&
			new Date().getTime() - Number(localStorage.timestamp) < 900000
		) {
			console.log('time is good')
			axios.defaults.headers.common.authorization = `Bearer ${localStorage.token}`
			console.log(axios.defaults)
			setValidated(true)
		} else {
			setValidated(false)
		}
	}, [])
	let StoreLoginDetails = (response) => {
		if (rememberMe) {
			localStorage.clear()
			sessionStorage.clear()
			localStorage.setItem('user', JSON.stringify(response.data))
		} else {
			sessionStorage.clear()
			sessionStorage.setItem('user', JSON.stringify(response.data))
		}
	}

	let HandleInputChange = (input) => {
		if (userResponseData === undefined) {
			setFormData((prevState) => ({
				...prevState,
				[input.target.name]: [input.target.value],
			}))
		}
		if (errorMsg) {
			setErrorMsg(null)
		}
	}

	let checkFormData = () => {
		if (formData.identifier !== '' && formData.password !== '') {
			return true
		} else return false
	}

	const HandleLoginSubmitAsync = async (e) => {
		if (checkFormData()) {
			e.preventDefault()
			let loginData = {
				userName: formData.identifier[0],
				password: formData.password[0],
			}
			return await axios({
				method: 'POST',
				url: baseUrl + '/login',
				data: loginData,
			})
				.then(function (response) {
					setIsLoggedIn(response.data)
					setUserResponseData(response.data)
					StoreLoginDetails(response)
				})
				.catch(function (error) {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log(error.response)
						setErrorMsg('Invalid emailId or password')
						// let errorMessages = error.response.data.data[0].messages[0]
						// alert(`id : ${errorMessages.id} \nmessage : ${errorMessages.message}`)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						setErrorMsg('Something went wrong')
						console.log('Error', error.message)
						// alert(`message : ${error.message}`)
					}
				})
		} else {
			// alert('Please fill all the details')
			setErrorMsg('Please fill valid details')
		}
	}

	return (
		<div
			className={css(styles.container, styles.noInteration)}
			id="containerDiv"
		>
			<div className={css(styles.containerCenter, styles.noInteration)}>
				<div className={css(styles.title, styles.noInteration)}>
					Log in{' '}
				</div>
				<div className={css(styles.formContainer)}>
					<form>
						<div className={css(styles.inputDiv)}>
							<input
								className={css(styles.input)}
								type="text"
								name="identifier"
								value={formData.identifier}
								onChange={HandleInputChange}
								placeholder="Email"
								id="text"
								onFocus={focusInput}
							/>
							{errorMsg != null && (
								<div className={css(styles.errotText)}>
									{errorMsg}
								</div>
							)}
						</div>
						<div className={css(styles.inputDiv)} id="inputDiv">
							<input
								className={css(styles.input)}
								type="password"
								name="password"
								value={formData.password}
								onChange={HandleInputChange}
								placeholder="Password"
								id="text"
							/>
							{errorMsg != null && (
								<div className={css(styles.errotText)}>
									{errorMsg}
								</div>
							)}
						</div>
					</form>
				</div>
				<div className={css(styles.buttonContainer)}>
					<button
						className={css(styles.button)}
						style={{ pointerEvents: 'auto' }}
						type="submit"
						onClick={HandleLoginSubmitAsync}
					>
						Login
					</button>
				</div>
			</div>
		</div>
	)
}

export default AuthUserUI

const styles = StyleSheet.create({
	noInteration: {
		pointerEvents: 'none',
	},
	container: {
		position: 'fixed',
		left: 0,
		right: 0,
		bottom: 0,
		backgroundImage: `url('./BGSplash.jpg')`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center bottom',
		width: '100%',
		height: '100%',
		minHeight: '100vh',
		minWidth: '100vw',
		'@media(max-width: 576px) and (orientation:portrait)': {
			overflow: 'scroll',
		},
	},
	containerCenter: {
		position: 'absolute',
		width: 300,
		height: 'auto',
		textAlign: 'center',
		top: '50%',
		left: '50%',
		right: 0,
		bottom: 0,
		transform: 'translate(-50%, -50%)',
		'@media(max-width: 576px) and (orientation:portrait)': {
			width: 250,
		},
		'@media(min-width: 576px) and (orientation:landscape)': {
			width: 250,
		},
		'@media(min-width: 992px)': {
			width: 300,
		},
		'@media(min-width: 1920px)': {
			width: '40vh',
		},
	},
	title: {
		fontSize: 64,
		lineHeight: '150%',
		color: '#FFFFFF',
		fontFamily: 'Work Sans',
		'@media(max-width: 576px) and (orientation:portrait)': {
			fontSize: 42,
		},
		'@media(min-width: 576px) and (orientation:landscape)': {
			fontSize: 28,
			lineHeight: '120%',
		},
		'@media(min-width: 992px)': {
			fontSize: 64,
			lineHeight: '150%',
		},
		'@media(min-width: 1920px)': {
			fontSize: '8vh',
			lineHeight: '150%',
		},
	},
	desc: {
		fontSize: 16,
		lineHeight: '120%',
		color: '#FFFFFF',
		fontFamily: 'Work Sans',
		marginTop: '2%',
		'@media(max-width: 576px) and (orientation:portrait)': {
			fontSize: 14,
		},
		'@media(min-width: 576px) and (orientation:landscape)': {
			fontSize: 12,
		},
		'@media(min-width: 992px)': {
			fontSize: 16,
		},
		'@media(min-width: 1920px)': {
			fontSize: '2.5vh',
		},
	},
	inputDiv: {
		marginTop: '10%',
		width: '100%',
		height: 45,
		'@media(min-width: 576px) and (orientation:landscape)': {
			marginTop: '5%',
			height: 38,
		},
		'@media(min-width: 992px)': {
			marginTop: '10%',
			height: 45,
		},
		'@media(min-width: 1920px)': {
			height: '6vh',
		},
	},
	input: {
		width: '100%',
		height: '100%',
		padding: '8%',
		borderRadius: '0.6vw',
		border: 'none',
		outline: 'none',
		fontSize: 16,
		fontFamily: 'Work Sans',
		lineHeight: '120%',
		color: '#ffffff',
		backgroundColor: '#224757',
		pointerEvents: 'auto',
		'::placeholder': {
			color: 'white',
			opacity: 0.7,
		},
		'@media(max-width: 576px) and (orientation:portrait)': {
			fontSize: 14,
			borderRadius: 8,
		},
		'@media(min-width: 576px) and (orientation:landscape)': {
			fontSize: 14,
			borderRadius: 8,
		},
		'@media(min-width: 992px)': {
			fontSize: 16,
			borderRadius: 8,
		},
		'@media(min-width: 1920px)': {
			fontSize: '1.8vh',
			borderRadius: '0.8vw',
		},
	},
	actionDiv: {
		marginTop: 23,
		width: 300,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	mainDiv: {
		float: 'left',
		color: '#ffffff',
		textAlign: 'center',
		marginTop: '8%',
		marginLeft: 0,
		fontSize: `${isMobileOnly ? '3vh' : '1.8vh'}`,
		width: 120,
		height: 20,
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		'@media(max-width: 576px) and (orientation:portrait)': {
			fontSize: 14,
			width: 130,
			marginTop: '8%',
		},
		'@media(min-width: 576px) and (orientation:landscape)': {
			fontSize: 12,
			width: 120,
			height: 10,
			marginTop: '5%',
		},
		'@media(min-width: 992px)': {
			fontSize: 14,
			width: 135,
			height: 25,
		},
		'@media(min-width: 1920px)': {
			fontSize: '1.8vh',
			width: '17vh',
			height: '2.5vh',
			marginTop: '8%',
		},
	},
	formContainer: {
		marginTop: '8%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	checkboxIn: {
		position: 'absolute',
		clip: 'rect(1px, 1px, 1px, 1px)',
		padding: 0,
		border: 0,
		height: 1,
		width: 1,
		overflow: 'hidden',
		pointerEvents: 'auto',
	},
	label01: {
		display: 'flex',
		alignItems: 'center',
		fontFamily: 'Work Sans',
		fontSize: '14px',
		lineHeight: '17.07px',
		pointerEvents: 'auto',
		':before': {
			content: "''",
			width: 20,
			height: 20,
			background: '#224957',
			border: 'none',
			borderRadius: 5,
			display: 'inline-block',
			marginRight: 8,
		},
		':checked': {
			background: '#20bf6b',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: '12px 12px',
		},
	},
	linkDiv: {
		float: 'right',
		color: '#268ACC',
		fontFamily: 'Work Sans',
		fontSize: '14px',
		lineHeight: '17.07px',
	},
	link: {
		color: '#268ACC',
		pointerEvents: 'auto',
		':hover': {
			cursor: 'pointer',
		},
	},
	buttonContainer: {
		marginTop: '25%',
		'@media(max-width: 576px) and (orientation:portrait)': {
			marginTop: '25%',
		},
		'@media(min-width: 576px) and (orientation:landscape)': {
			marginTop: '15%',
		},
		'@media(min-width: 992px)': {
			marginTop: '25%',
		},
		'@media(min-width: 1920px)': {
			marginTop: '25%',
		},
	},
	button: {
		width: '100%',
		height: 45,
		backgroundColor: '#268ACC',
		borderRadius: '0.6vw',
		border: 'none',
		outline: 'none',
		color: '#ffffff',
		fontSize: 16,
		lineHeight: '120%',
		fontFamily: 'Work Sans',
		':hover': {
			cursor: 'pointer',
		},
		'@media(max-width: 576px) and (orientation:portrait)': {
			fontSize: 14,
			borderRadius: 8,
		},
		'@media(min-width: 576px) and (orientation:landscape)': {
			fontSize: 14,
			borderRadius: 8,
			height: 38,
		},
		'@media(min-width: 992px)': {
			fontSize: 16,
			borderRadius: 8,
			height: 45,
		},
		'@media(min-width: 1920px)': {
			fontSize: '2vh',
			borderRadius: '0.8vw',
			height: '6vh',
		},
	},
	errotText: {
		fontSize: '2vh',
		color: '#DA1212',
		fontFamily: 'Work Sans',
		marginTop: '0.5%',
		textAlign: 'left',
	},
})
