import { StyleSheet } from 'aphrodite'
const commonStyles = StyleSheet.create({
	paragraph: {
		fontFamily: 'Helvetica',
		color: '#093145',
		fontSize: 18,
	},
	heading: {
		fontFamily: 'Helvetica',

		color: '#093145',
		fontSize: 27,
	},
})

export default commonStyles
