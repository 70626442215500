import { css, StyleSheet } from 'aphrodite'
import { useState } from 'react'
import fadeIn from 'react-animations/lib/fade-in'
import { isAndroid, isDesktop, isIOS, isMobile } from 'react-device-detect'
import { useQR } from '../store'
import ARView from '../svgs/ARView'
import ARViewDark from '../svgs/ARViewDark'
import ButtonImgDark from './textures/ar-dark.png'
import ButtonImg from './textures/AR.svg'
import { a as animated } from '@react-spring/web'
import { sendEvent } from '../helpers/google-analytics'
/*
Change only the below two
*/
const getGlb = (name) => {
  switch (name) {
    case 'ballot':
      return 'ballot_box.glb'
    case 'refrigerator':
      return 'Refrigerator_10.glb'
    case 'navtal':
      return 'NAVTAL_02.glb'
    case 'squarelock':
      return 'Lock_10.glb'
    case 'typewriter':
      return 'Typewriter_06.glb'
  }
}
const getUsdz = (name) => {
  switch (name) {
    case 'ballot':
      return 'ballot_box.usdz'
    case 'refrigerator':
      return 'refrigerator.usdz'
    case 'navtal':
      return 'navtal.usdz'
    case 'squarelock':
      return 'squarelock.usdz'
    case 'typewriter':
      return 'typewriter.usdz'
  }
}
export default function AR(props) {
  const baseurl = `https://aws.autovrse.in/staging/godrej/archives/Models/`
  const AndroidURL = props.androidURL
  //   "https://aws.autovrse.in/godrej-lock/_webar/AdvantisLock.glb";
  // const AndroidURL = `./Models/ballot_box.glb`
  const IOSIntent = props.appleUrl
  //   "https://aws.autovrse.in/godrej-lock/_webar/AdvantisLock.usdz";
  const qr = useQR()
  const AndroidIntent =
    'intent://arvr.google.com/scene-viewer/1.0?file=' +
    AndroidURL +
    '#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;'

  function OnRequestAR() {
    window.open(AndroidIntent)
    sendEvent(`Android AR Clicked`, 'AR', 'AR Button')
  }
  function OnRequestARDesktop() {
    sendEvent(`QR Code Clicked`, 'AR', 'AR Button')
    if (props.dimension) {
      props.setDimension(false)
      props.setDimensionStatus('closed')
    }
    qr.setQR(
      `https://master.dy2v8xoym67fn.amplifyapp.com/?android=${AndroidURL}&ios=${IOSIntent}`
    )
  }
  const defaultOptions = {
    autoplay: false,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    loop: false,
  }
  return (
    <>
      {isAndroid && (
        <>
          <button
            onClick={OnRequestAR}
            className={props.buttonStyle}
            style={{
              pointerEvents: !props.showHud ? 'none' : 'auto',
            }}
          >
            {/* <img
						alt="Start AR"
						className={css(styles.image)}
						src={ButtonImg}
					/> */}
            {/* {props.isDark ? <ARViewDark /> : <ARView />} */}
            <ARView svgStyle={props.svgStyle} />
          </button>

          {/* <p className={css(styles.toolTip)}>See product in AR</p> */}
        </>
      )}
      {isIOS && (
        <>
          {/* <button className={css(styles.button)}> */}
          <a
            rel="ar"
            href={IOSIntent}
            onClick={() => sendEvent(`iOS AR Clicked`, 'AR', 'AR Button')}
            className={props.buttonStyle}
            style={{
              pointerEvents: !props.showHud ? 'none' : 'auto',
            }}
          >
            {/* <img
						alt="Start AR"
						src={ButtonImgDark}
						className={css(styles.image)}
					/> */}
            <img src="/AR.svg" className={props.svgStyle} alt="AR ICON" />
            {/* <ARView svgStyle={props.svgStyle} /> */}
          </a>
          {/* </button> */}
          {/* <p className={css(styles.toolTip)}>See product in AR</p> */}
        </>
      )}
      {isDesktop && (
        <>
          <button
            onClick={OnRequestARDesktop}
            // className={css(styles.button)}
            className={props.buttonStyle}
            onPointerEnter={() => props.setTooltipHover('ARTooltip')}
            onPointerLeave={() => props.setTooltipHover(null)}
            style={{
              pointerEvents: !props.showHud ? 'none' : 'auto',
            }}
          >
            {/* <img
							alt="Start AR"
							className={css(styles.image)}
							src={ButtonImg}
						/> */}
            {/* {props.isDark ? <ARViewDark /> : <ARView />} */}
            <ARView svgStyle={props.svgStyle} />
          </button>

          {/* <p className={css(styles.toolTip)}>See product in AR</p> */}
        </>
      )}
      <animated.div
        className={css(styles.toolTipContainer)}
        style={props.ARTooltipHover}
      >
        <div className={css(styles.toolTipTriangle)}></div>
        <div className={css(styles.skyboxToolTip)}>
          View product in your space
        </div>
      </animated.div>
    </>
  )
}

const styles = StyleSheet.create({
  toolTipContainer: {
    position: 'absolute',
    top: '-25%',
    // left: 0,
    // display: 'flex',
    // flexDirection: 'column',
    '@media(max-width: 576px) and (orientation:portrait)': {
      top: '-58%',
    },
    '@media(min-width: 577px) and (max-width: 767px) and (orientation:landscape)':
      {
        top: '-60%',
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        top: '-60%',
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:landscape)':
      {
        top: '-60%',
      },
    '@media(min-width: 1200px)': {
      // top: '-45%',
      top: '4.2em',
    },
    '@media(min-width: 1920px)': {
      top: '100%',
    },
  },
  toolTipTriangle: {
    width: 0,
    height: 0,
    borderLeft: '.6rem solid transparent',
    borderRight: '.6rem solid transparent',
    // borderBottom: '.8rem solid rgba(0, 0, 0, .7)',
    marginLeft: '2vh',

    position: 'absolute',
    marginTop: 25,
    borderTop: '.8rem solid rgba(0, 0, 0, .7)',
    // position: 'absolute',
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        marginTop: 37,
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        marginTop: 30,
      },
    '@media(min-width: 1200px) and (orientation:landscape)': {
      marginTop: 30,
    },
    '@media(min-width: 1400px)': {
      borderBottom: '.8rem solid rgba(0, 0, 0, .7)',
      borderTop: 'unset',
      top: 0,
      marginTop: -12,
    },
  },
  skyboxToolTip: {
    display: 'block',
    whiteSpace: 'nowrap',
    background: 'rgba(0, 0, 0, .7)',
    textAlign: 'center',
    padding: '0.35rem 0.9rem',
    fontSize: '0.75rem',
    color: '#fff',
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        fontSize: '1.25rem',
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        fontSize: '1rem',
      },
    '@media(min-width: 1400px)': {
      fontSize: '0.8vw',
    },
  },
  container: {
    // position: 'absolute',
    // top: '1vh',
    // left: '1.5vh',
    // zIndex: 120,
    // textAlign: 'center',
    // '@media (max-width: 1100px)': {
    //   transform: 'scale(0.7)',
    // },
  },
  button: {
    outline: 'none',
    background: 'none',
    cursor: 'pointer',
    border: 'none',

    // width: '4.5vw',
    // marginLeft: '0.8vh',
    width: 'clamp(4.5vw, 9.8vh, 30px)',
    // width: 'clamp(2.8rem, 3.3vw, 100px)',
    padding: 0,
    height: 'auto',
    '@media(min-width: 1400px)': {
      width: '3.65vw',
    },
    // ':active': {
    //   transform: 'scale(0.8)',
    // },
    // animationDuration: '1s, 1200ms',
    // animationIterationCount: 'infinite',
    // animationFillMode: 'forwards',
    // ':hover': {
    //   transform: 'scale(1.1)',
    // },
  },
  image: {
    height: '5.7vh',
    animationDuration: '1s, 1200ms',
    animationIterationCount: 'infinite',
    animationFillMode: 'forwards',
  },
  qrcontainer: {
    animationName: fadeIn,
    animationDuration: '1s, 1200ms',
    animationIterationCount: 'once',
    animationFillMode: 'forwards',
  },
  qr: {
    height: 150,
  },
  qrButton: {
    outline: 'none',
    background: 'none',
    border: 'none',
  },
  toolTip: {
    width: '7rem',
    background: 'rgba(0, 0, 0, .7)',
    // borderRadius: 5,
    textAlign: 'center',
    padding: '0.2rem',
    fontSize: '0.75rem',
    color: '#fff',
    margin: 0,
    position: 'absolute',
    bottom: '-50%',
    display: 'none',
    ':before': {
      content: "''",
      border: '.4rem solid rgba(0, 0, 0, .7)',
      borderBottomColor: '#fff',
      borderRightColor: '#fff',
      position: 'absolute',
      top: '-28%',
      left: '23%',
      transform: 'translateX(-50%) rotate(45deg)',
      zIndex: '-99',
    },
  },
})
