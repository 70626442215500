import { useFrame, useThree } from '@react-three/fiber'
import { useEffect } from 'react'
import * as THREE from 'three'

const Camera = ({ position, zoom, controls }) => {
  const { camera } = useThree()

  const cameraPos = new THREE.Vector3(...Object.values(position || {}))

  const defaultCameraDistance = cameraPos.distanceTo(new THREE.Vector3(0, 0, 0))

  const maxZoom = defaultCameraDistance + defaultCameraDistance / 2

  const minZoom = defaultCameraDistance - defaultCameraDistance / 2

  useEffect(() => {
    // console.log('position', position, ...Object.values(position))
    camera.position.set(...Object.values(position || {}))
    camera.zoom = zoom
    // controls.current.minDistance = minZoom
    // controls.current.maxDistance = maxZoom
    // console.log('zoom', zoom)
  }, [position, camera, zoom])

  useFrame(() => controls.current.update())
  return null
}

export default Camera
